import { calculateGdd, simpleAve } from "../../utils/helpers"
import { format } from "date-fns"

export default function WesternBeanCutwormLogic(modelData, data) {
  const {  base } = modelData

  const dailyDataWithGdd = calculateGdd(data.dailyData, base, 0, simpleAve).map(
    (d) => {
      return { ...d, dateDisplay: format(d.date, "yyyy-MM-dd") }
    }
  )

  return {
    data: dailyDataWithGdd
  }
}

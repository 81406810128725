import React from "react";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./western-bean-cutworm.json";
import Acknowledgments from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function WesternBeanCutworm() {
  const h1Text = modelData.title;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, modelData.seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  let mData = null;
  if (data && station) {
    mData = modelLogic(modelData, data, dateOfInterest);

    // Convert fahrenheit to celcius | @danolmstead 20221226
    function F_to_C(a) {
      return (a - 32) * (5 / 9);
    }

    // Calculate average temperature | @danolmstead 20221226
    function AVERAGE_TEMPERATURE(lowTemp, highTemp) {
      return (highTemp + lowTemp) / 2;
    }

    // Calculate daily degree day heat units | @danolmstead20221226
    function DAILY_DEGREE_DAYS(lowTemp, highTemp) {
      const LOWER_THRESHOLD = 3.3;
      const UPPER_THRESHOLD = 23.9;
      if (highTemp > UPPER_THRESHOLD) {
        return (UPPER_THRESHOLD + lowTemp) / 2 - LOWER_THRESHOLD;
      } else if (highTemp < LOWER_THRESHOLD) {
        return 0;
      } else if ((highTemp + lowTemp) / 2 - LOWER_THRESHOLD < 0) {
        return 0;
      } else {
        return (highTemp + lowTemp) / 2 - LOWER_THRESHOLD;
      }
    }

    // Generate flight completion data | @danolmstead 20221226
    function FLIGHT_COMPLETION(a) {
      if (a < 1321) {
        return "Not started";
      } else if (a >= 1321 && a < 1364) {
        return "5%";
      } else if (a >= 1364 && a < 1432) {
        return "10%";
      } else if (a >= 1432 && a < 1502) {
        return "25%";
      } else if (a >= 1502 && a < 1577) {
        return "50%";
      } else if (a >= 1577 && a < 1654) {
        return "75%";
      } else if (a >= 1654 && a < 1709) {
        return "90%";
      } else if (a >= 1709) {
        return "Complete";
      }
    }

    // Generate custom mData object output for western bean cutworm | @danolmstead 20221226
    let ACCUMULATOR = 0;
    for (let i = 0; i < mData.data.length; i++) {
      mData.data[i]["maxt_C"] = Math.round(F_to_C(mData.data[i]["maxt"]));
      mData.data[i]["mint_C"] = Math.round(F_to_C(mData.data[i]["mint"]));
      mData.data[i]["avet_C"] = Math.round(
        AVERAGE_TEMPERATURE(mData.data[i]["mint_C"], mData.data[i]["maxt_C"])
      );
      mData.data[i]["avet"] = Math.round(
        AVERAGE_TEMPERATURE(mData.data[i]["mint"], mData.data[i]["maxt"])
      );
      if (mData.data[i]["dayOfYear"] < 60 || mData.data[i]["dayOfYear"] > 305) {
        mData.data[i]["dd_3pt3_C"] = 0;
      } else {
        mData.data[i]["dd_3pt3_C"] = Math.round(
          DAILY_DEGREE_DAYS(mData.data[i]["maxt_C"], mData.data[i]["mint_C"])
        );
      }
      ACCUMULATOR = ACCUMULATOR + mData.data[i]["dd_3pt3_C"];
      mData.data[i]["dd_3pt3_C_ACC"] = ACCUMULATOR;
      mData.data[i]["dd_3pt3_C_ACC_FLIGHT"] = FLIGHT_COMPLETION(
        mData.data[i]["dd_3pt3_C_ACC"]
      );
    }
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const isVisible = station && mData;

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      moreInfo={<MoreInfo moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable station={station} data={mData.data}></ResultsTable>
        </div>
      )}
    </ModelLayout>
  );
}

import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      Hanson, A. A., R. D. Moon, R. J. Wright, T. E. Hunt, and W. D. Hutchison. 2015. Degree-Day Prediction Models for the Flight Phenology of Western Bean Cutworm (Lepidoptera: Noctuidae) Assessed with the Concordance Correlation Coefficient. Journal of Economic Entomology. 108: 1728–1738. <a href="https://doi.org/10.1093/jee/tov110" target="blank" rel="noreferrer">https://doi.org/10.1093/jee/tov110</a>
      </p>
    </>
  )
}

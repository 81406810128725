import React from "react"

export default function MoreInfo({ moreInfo }) {
  return (
    <div>
      <p className="text-sm leading-5 text-left text-gray-500">WBC is native to north America and has migrated east from its historical range in the Great Plains region. It arrived in New York in 2009, and populations reached economically damaging levels in 2015. It overwinters as larvae, especially in areas with sandier soil, and adults emerge in late July to early August with peak flight occurring the first week of August. Eggs are laid on the top surface of leaves closest to the tassel. Larvae will feed on leaves, but the primary damage is seen in the corn ear. It can enter in through the side of the ear or the silk channels. Larvae are not cannibalistic; often more than one occurs in an ear. The crochets are in a mesoseries similar to FAW and CEW.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">Excerpted from <b>Zuefle, M. 2015.</b> Sweet Corn Larval Pest Identification. New York State Integrated Pest Management. Cornell University. <a href="https://hdl.handle.net/1813/57328" target="blank" rel="noreferrer">https://hdl.handle.net/1813/57328</a></p>
    </div>
  )
}
